module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://tinfoilmylife.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tinfoil My Life","short_name":"TinfoilMyLife","description":"\"Put a tinfoil hat on your digital self!","start_url":"/","background_color":"#ecf8f8","theme_color":"#fe7f2d","display":"standalone","icon":"static/apple-touch-icon.png","lang":"en","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"167923477ffe3d56523cfcaba3454b85"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
